import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyModal from "@/components/ApplyModal";
import { usePhoneNumberState } from '@/utils/PhoneNumberContext';
import { Menu } from "antd";
import "./index.scss";
const tabList = [
  {
    label: "首页",
    key: "home",
  },
  {
    label: "商超版",
    key: "supermarkets",
  },
  {
    label: "前置仓版",
    key: 'front-warehouse',
    // children: [
    //   {
    //     label: "前置仓",
    //     key: "front-warehouse",
    //   },
    //   {
    //     label: "无人仓",
    //     key: "no-warehouse",
    //   },
    // ],
  },
  {
    label: "外卖版",
    key: "take-out-v",
  },
  {
    label: "开放平台",
    key: "open",
  },
  {
    label: "关于我们",
    key: "about",
  },
];
const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tabActive, setTabActive] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isNeedNobackground, setIsNeedNobackground] = useState(true);
  const { phoneNumber, setPhoneNumber } = usePhoneNumberState();

  useEffect(() => {
    setTabActive(pathname.split("/")[1]);
    // 滚动条滚动时触发
    window.addEventListener("scroll", scrollChange, true);
  }, [pathname]);

  const scrollChange = () => {
    // 监听滚动条距离顶部距离
    if (document.documentElement.scrollTop > 3) {
      setIsNeedNobackground(false);
    } else {
      setIsNeedNobackground(true);
    }
  };

  const handleChange = (key) => {
    if (key === "open") {
      let url =
        window.location.href.indexOf("pre") > -1 ||
        window.location.href.indexOf("localhost") > -1
          ? "https://p-open.hiboos.com"
          : "https://api-open.hiboos.com";
      window.open(url, "_blank");
    } else {
      navigate(`/${key}`);
      setTabActive(key);
    }
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div
        className={
          isNeedNobackground
            ? "header_wrapper_d min_width"
            : "header_wrapper_h min_width"
        }
      >
        <div className="header_main">
          <div className="jd_icon_wrapper"></div>
          <div className="hiboos_icon_wrapper"></div>
          <div className="switch_tab_wrapper">
            {tabList?.map(item => {
              return (
                <span
                  key={item.key}
                  className={item.key === tabActive ? 'tab_item tab_item_active' : 'tab_item'}
                  onClick={() => {
                    handleChange(item.key)
                  }}
                >
                  {item.label}
                </span>
              )
            })}
            {/* <Menu
              onClick={handleChange}
              selectedKeys={[]}
              mode="horizontal"
              items={tabList}
            /> */}
          </div>
        </div>
        <div className="header_right">
          <div className="header_right_telephone">
            <span className="phone_icon">
              <img
                width={14}
                height={14}
                src={require("@/images/header_phone.gif")}
                alt=""
              />
            </span>
            <span className="telephone">{phoneNumber}</span>
          </div>
          <span className="header_right_btn" onClick={modalShow}>
            免费咨询
          </span>
        </div>
      </div>
    </div>
  );
};
export default Header;
