/* eslint-disable react/no-unused-state */
import React, { useState, useEffect } from 'react'
import { usePhoneNumberState } from '@/utils/PhoneNumberContext';
import './index.scss';
const qrCodeList = [
  {
    imgUrl: 'wx_chat',
    text1: '关注微信公众号',
    text2: '获取更多资讯'
  },
  {
    imgUrl: 'dy_hiboos',
    text1: '关注抖音视频号',
    text2: '了解海博功能'
  },
  {
    imgUrl: 'dy_business',
    text1: '关注抖音视频号',
    text2: '了解商户使用详情'
  },
]
const Footer = () => {
  const { phoneNumber, setPhoneNumber } = usePhoneNumberState()

  return (
    <div className='footer_wrapper'>
      <div className='wrapper'>
        <p className='title_wrapper'>
          <span className='title_text'>达达海博</span>
        </p>
        <div className='main_content'>
          <div className='main_content_left'>
            <p className='item' style={{ color: '#111111', fontWeight: '500' }}>联系我们</p>
            <p className='item'>联系电话：{phoneNumber}</p>
            <p className='item'>经营许可证编号 <a href="http://beian.miit.gov.cn" target="_blank">京ICP备19003742号-1</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;京公网安备 11030102011104号</p>
            <p className='item'>北京达冠信息技术有限公司&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;地址：北京通州区京东总部1号楼</p>
          </div>
          <div className='main_content_right'>
            {qrCodeList.map(item => {
              return (
                <div className='item' key={item.imgUrl}>
                  <img height={100} src={require(`@/images/homePage/${item.imgUrl}.png`)} alt="" />
                  <p>{item.text1}</p>
                  <p>{item.text2}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer
